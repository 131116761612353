.step {
  height: 75px;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 300px;
  max-width: 500px;
  overflow: hidden;
}

.child {
  height: 60px;
  display: flex;
  flex-direction: column;
  border: 1px dashed;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.step--failure {
  border: 1px solid #E80045;
}

.step--disabled {
  background-color: #E9EBEC;
  color: #929AA1;
  cursor: not-allowed;
}

.port {
  background-color: #28d454;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  color: white;
}

.link {
  position: absolute;
  background-color: #E80045;
  color: white;
  font-weight: bold;
  font-size: 0.75em;
  padding: 0.25em;
  border-radius: 0.25em;
}

.link-error path {
  stroke: #E80045;
}