@font-face {
  font-family: "Sharp Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Sharp Sans Light"), local("SharpSans-Light"),
    url("./assets/fonts/sharp-sans/SharpSans-Light.woff2") format("woff2"),
    url("./assets/fonts/sharp-sans/SharpSans-Light.woff") format("woff");
}

@font-face {
  font-family: "Sharp Sans";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("Sharp Sans Book"), local("SharpSans-Book"),
    url("./assets/fonts/sharp-sans/SharpSans-Book.woff2") format("woff2"),
    url("./assets/fonts/sharp-sans/SharpSans-Book.woff") format("woff");
}

@font-face {
  font-family: "Sharp Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Sharp Sans Medium"), local("SharpSans-Medium"),
    url("./assets/fonts/sharp-sans/SharpSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/sharp-sans/SharpSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "Sharp Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Sharp Sans Semibold"), local("SharpSans-Semibold"),
    url("./assets/fonts/sharp-sans/SharpSans-Semibold.woff2") format("woff2"),
    url("./assets/fonts/sharp-sans/SharpSans-Semibold.woff") format("woff");
}

@font-face {
  font-family: "Sharp Sans";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local("Sharp Sans Bold"), local("SharpSans-Bold"),
    url("./assets/fonts/sharp-sans/SharpSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/sharp-sans/SharpSans-Bold.woff") format("woff");
}
