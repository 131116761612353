.step {
  height: 130px;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 800px;
  overflow: hidden;
  word-break: break-all;
  text-align: center;
}

.step__conditional {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 0.5em;
}


.port {
  background-color: #28d454;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  color: white;
}

.step-modal > div {
  height: 75%;
  width: 80%;
  max-width: 80%;
  overflow-y: scroll;
  margin-left: 20rem;
  margin-right: 8rem
}

.step-modal__code {
  white-space: break-spaces;
}
