.Toastify__toast--success {
  background: #33cc60;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
}

.Toastify__toast--error {
  background: #ef5f2f;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
}

.Toastify__toast--info {
  background: #3498db;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
}
