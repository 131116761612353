.rg-loader > svg * {
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

@keyframes top-group-animation {
  11.11% {
    opacity: 0;
  }
  38.89% {
    opacity: 1;
  }
  66.67% {
    opacity: 0;
  }
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.rg-loader .top-group {
  transform: translate(8px, 0.295848px);
  animation-fill-mode: backwards;
  opacity: 0;
  animation-name: top-group-animation;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

@keyframes right-group-animation {
  0% {
    opacity: 0;
  }
  27.78% {
    opacity: 1;
  }
  55.56% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.rg-loader .right-group {
  transform: translate(14.389142px, 0px);
  animation-fill-mode: backwards;
  opacity: 0;
  animation-name: right-group-animation;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

@keyframes bottom-group-animation {
  38.89% {
    opacity: 0;
  }
  66.67% {
    opacity: 1;
  }
  94.44% {
    opacity: 0;
  }
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.rg-loader .bottom-group {
  transform: translate(0.710995px, 14.629396px);
  animation-fill-mode: backwards;
  opacity: 0;
  animation-name: bottom-group-animation;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

@keyframes left-group-animation {
  27.78% {
    opacity: 0;
  }
  55.56% {
    opacity: 1;
  }
  83.33% {
    opacity: 0;
  }
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.rg-loader .left-group {
  transform: translate(0px, 0.591912px);
  animation-fill-mode: backwards;
  opacity: 0;
  animation-name: left-group-animation;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
}

.rg-loader #TopPath {
  fill: #29d454;
}

.rg-loader #TopUse {
  fill: #d8d8d8;
  opacity: 0;
}

.rg-loader #RightPath {
  fill: #29d454;
}

.rg-loader #RightUse {
  fill: #d8d8d8;
  opacity: 0;
}

.rg-loader #BottomPath {
  fill: #29d454;
}

.rg-loader #LeftUse {
  fill: #d8d8d8;
  opacity: 0;
}

.rg-loader #LeftPath {
  fill: #29d454;
}

@keyframes dot-pulse {
  50% {
    opacity: 0.2;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.rg-loader .dot:nth-child(1) {
  animation: dot-pulse 0.6s -0.14s infinite ease-in-out;
}

.rg-loader .dot:nth-child(2) {
  animation: dot-pulse 0.6s -0.07s infinite ease-in-out;
}

.rg-loader .dot:nth-child(3) {
  animation: dot-pulse 0.6s 0s infinite ease-in-out;
}

.rg-loader .dot {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 2px;

  animation-fill-mode: both;
  display: inline-block;
}
