.rg-date-range-picker,
.rg-date-picker {
  height: 36px;
}

.rg-date-range-picker .DateRangePickerInput,
.rg-date-picker .SingleDatePickerInput {
  height: 36px;
}

.rg-date-range-picker .DateRangePickerInput__withBorder,
.rg-date-picker .SingleDatePickerInput__withBorder {
  border-color: #313336;
  border-radius: 0.25rem;
}

.rg-date-range-picker.-danger .DateRangePickerInput__withBorder,
.rg-date-picker.-danger .SingleDatePickerInput__withBorder {
  border-color: rgb(232, 0, 69);
}

.rg-date-range-picker .DateRangePickerInput__withBorder:focus-within,
.rg-date-picker .SingleDatePickerInput__withBorder:focus-within {
  border-color: #29d454;
}

.rg-date-range-picker .DateInput,
.rg-date-picker .DateInput {
  background: transparent;
}

.rg-date-range-picker .DateInput_input,
.rg-date-picker .DateInput_input {
  border: none;
  height: 34px;
  padding: 0.375rem 0.5rem;
  background: transparent;
}

.rg-date-range-picker .DateInput__small,
.rg-date-picker .DateInput__small {
  width: 105px;
}

.rg-date-range-picker .DateInput_input__disabled,
.rg-date-picker .DateInput_input__disabled {
  font-style: normal;
}

.rg-date-range-picker .DateRangePickerInput_clearDates__small,
.rg-date-picker .SingleDatePickerInput_clearDate__small {
  padding: 6px;
  height: 24px;
  width: 24px;
  display: none;
  justify-content: center;
  align-items: center;
}

@media (min-width: 412px) {
  .rg-date-range-picker .DateRangePickerInput_clearDates__small,
  .rg-date-picker .SingleDatePickerInput_clearDate__small {
    display: inline-flex;
  }
}

.rg-date-range-picker .DateRangePickerInput_calendarIcon,
.rg-date-picker .SingleDatePickerInput_calendarIcon {
  padding: 2px 10px;

  display: none;
}

@media (min-width: 412px) {
  .rg-date-range-picker .DateRangePickerInput_calendarIcon,
  .rg-date-picker .SingleDatePickerInput_calendarIcon {
    display: inline-block;
  }
}

.rg-date-range-picker .DayPicker_weekHeader,
.rg-date-picker .DayPicker_weekHeader {
  top: 56px;
}

.rg-date-range-picker .CalendarDay__selected,
.rg-date-range-picker .CalendarDay__selected:active,
.rg-date-range-picker .CalendarDay__selected:hover,
.rg-date-picker .CalendarDay__selected,
.rg-date-picker .CalendarDay__selected:active,
.rg-date-picker .CalendarDay__selected:hover {
  background: #575c61;
  border: 1px double #575c61;
  color: #fff;
}

.rg-date-range-picker .CalendarDay__selected_span,
.rg-date-picker .CalendarDay__selected_span {
  background: #e9ebec;
  border: 1px double #cacccf;
  color: #575c61;
}

.rg-date-range-picker .CalendarDay__selected_span:active,
.rg-date-range-picker .CalendarDay__selected_span:hover,
.rg-date-picker .CalendarDay__selected_span:active,
.rg-date-picker .CalendarDay__selected_span:hover {
  background: #cacccf;
}

.rg-date-range-picker .CalendarDay__hovered_span,
.rg-date-range-picker .CalendarDay__hovered_span:hover,
.rg-date-picker .CalendarDay__hovered_span,
.rg-date-picker .CalendarDay__hovered_span:hover {
  background: #cacccf;
  border: 1px double #e9ebec;
  color: #575c61;
}

.rg-date-range-picker .CalendarDay__hovered_span:active,
.rg-date-picker .CalendarDay__hovered_span:active {
  background: #e9ebec;
  border: 1px double #e9ebec;
  color: #575c61;
}
