.csv-reader {
  padding: 15px;
  display: block;
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
}

input.file {
  position: relative;
  text-align: right;
  opacity: 0;
  z-index: 2;
}
