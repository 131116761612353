.trading-partner-tabs {
    margin: 0 !important;
}

.contact-tabs {
    margin: 0 !important;
    height: 100%;
}

.contact-history-modal {
    height: 70vh;
}

.conversion-code-modal {
    height: 70vh;
}

.conversion-code-tabs {
    margin: 0 !important;
    height: 100%;
}

.notification-tabs {
    margin: 0 !important;
    height: 100%;
}

.notification-list {
    white-space: normal !important;
}
