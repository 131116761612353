.rg-modal {
  min-width: 400px;
}

.rg-modal > .rg-modal-body {
  max-height: 50vh;
}

.rg-modal > .rg-modal-header > hr {
  border-top: 1px solid #cacccf;
  border-bottom: 1px solid #fff;
}
